$fontAccent: "Gotham";

$primary: #00C078;
$secondary: #00C078;
$danger: #ff4136;
$dark: #333;
$light: rgb(248, 248, 248);
$success: #00C078;
$warning: #ffc107;
$info: #155B3B;

:root {
  --font-accent: "Gotham";
  --primary-dark: darken($primary, 5%);
  --primary-darker: darken($primary, 10%);
  --primary-darkest: darken($primary, 20%);
  --primary-lighterest: lighten($primary, 50%);
}
