@use "themes"as *;

@use '@nebular/theme/styles/globals'as *;

@import "variables";
@import "custombootstrap";
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "ngx-snotifire/styles/material";
@import "@circlon/angular-tree-component/css/angular-tree-component.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "ngx-sharebuttons/themes/material";
@import "ngx-sharebuttons/themes/modern";

@import "ag-grid-community/styles/ag-grid.css";

// set the colors to the ones from the material theme
$ag-mat-primary: mat-color(map-get($theme-colors, primary));
$ag-mat-accent: mat-color(map-get($theme-colors, accent));

// Import the ag-Grid material-next theme
@import "ag-grid-community/styles/ag-theme-material.css";

@import "fonts";

@include nb-install() {
  @include nb-theme-global();
}

html,
body {
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  background: map-get($theme-colors, light);
  font-size: 0.9em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500 !important;
  font-family: var(--font-accent) !important;
}

h3 {
  font-size: 22px;
  margin-top: 15px;
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: map-get($theme-colors, dark);
}

.on-sidebar {
  min-height: 100vh;
  max-width: 244px;
  background-color: map-get($theme-colors, primary);
  overflow-x: hidden !important;
}

.full-width {
  .modal-dialog {
    max-width: 90% !important;
  }
}

.ng-sidebar {
  z-index: 1040 !important;
}

.nb-theme-onexpense {
  nb-sidebar {
    background-color: #C7C8CA;
  }

  nb-menu .menu-item a {
    transition: 0.2s;
  }

  ngb-tabset ul a {
    text-decoration: none;
  }

  .cdk-overlay-container {
    z-index: 1100; //lower then fixed header z-index so it goes behind it
  }
}

.information-text {
  margin: 30px 0px 30px 0px;
  font-size: 15.5px;
  text-align: center;
  font-weight: bold;
  font-family: $fontAccent;
}

table.selectable {
  tr {
    &.selected-background {
      background: map-get($theme-colors, primary) !important;
      color: white;
    }
  }
}

.table-hover {
  tr {
    cursor: pointer;
  }
}

.form-control.ng-invalid.ng-touched {
  border: map-get($theme-colors, danger) 1px solid;
}

.user-hierarchy {
  .tree-node-wrapper {
    border: 1px solid map-get($theme-colors, primary);
    background-color: map-get($theme-colors, secondary);
  }
}

.ag-selection-checkbox {
  padding: 10px;
  margin-left: -10px;
}

//For overflow
#gridHierarchy>.ag-ltr {
  overflow: visible;
}

.info-text {
  color: cornflowerblue !important;
  font-size: 1em !important;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.5s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.vertical-center-container {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.withPaypalBtn {
  margin-top: -7px;
}

.margin-5 {
  margin: 5px;
}

.icon-warning {
  color: map-get($theme-colors, warning);
  font-size: 1.5em;
}

.icon-danger {
  color: map-get($theme-colors, danger);
  font-size: 1.5em;
}

.editable-cell {
  border-bottom: dashed 2px map-get($theme-colors, primary);
  padding: 0px 0px 4px 0px;
  cursor: pointer;
  z-index: 110000;
}

.editable-cell>.editable-value {
  padding-left: 0px;
}

.editable-cell>.editable-icon {
  padding-right: 0px;
  font-size: 1.3em;
}

.clickable-icon {
  cursor: pointer;
  font-size: 1.3em;
}

.button-with-icon {
  padding-left: 5px;
}

.icon-primary {
  color: map-get($theme-colors, primary);
}

.icon-danger {
  color: map-get($theme-colors, danger);
}

.clickable-icon {
  cursor: pointer;
  font-size: 1.3em;
}

.button-with-icon {
  padding-left: 5px;
}

.margin-5 {
  margin: 5px;
}

.icon-success {
  color: lighten($color: map-get($theme-colors, success), $amount: 20%);
  font-size: 1.5em;
}

.capitalize-word {
  text-transform: capitalize;
}

.ng-trigger-accordionItemBody>.item-body {
  background-color: lighten(map-get($theme-colors, light), 20%);
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.grey {
  color: lighten(map-get($theme-colors, dark), 20%);
}

.ag-tooltip {
  background-color: white !important;
}

.nb-theme-onexpense .item-body {
  overflow: hidden !important;
}

.nb-theme-onexpense nb-accordion nb-accordion-item {
  background-color: map-get($theme-colors, primary);
}

.input-rounded {
  border-radius: 2em;
}

//Fix the fact that modals are on top of the ng-select
.ng-dropdown-panel {
  z-index: 1080 !important;
}

.microsoft-button {
  display: block;
  padding-left: 12px;
  height: 41px;
  background: #2f2f2f;
  color: #fff !important;
  padding-top: 10px;
  font-weight: 600;

  font-family: "Segoe UI", Verdana, sans-serif;
  font-size: 15px;

  img {
    margin-right: 12px;
  }
}

.text-primary {
  color: map-get($theme-colors, primary);
}

.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

/* For the tabs */
ngb-tabset li a {
  color: rgba($color: #000000, $alpha: 0.35) !important;
  &.active {
      color: var(--dark) !important;
  }
}

// Overwite the color of the buttons (black by default)
.btn-primary {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
}

.btn-secondary {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
}

.btn-info {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
}

.btn-danger {
  --bs-btn-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
  --bs-btn-disabled-color: white;
}

.swal2-styled.swal2-confirm 
{
  background-color: map-get($theme-colors, primary) !important;
}