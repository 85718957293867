
@font-face {
    font-family: "Gotham";
    src: url("/assets/fonts/Gotham-Book.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-BookItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/Gotham-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
  
  